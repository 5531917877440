module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"Báyung Ángeles – Join the movement!","description":"Báyung Ángeles is a community advocating social change through responsible, effective, and transparent public governance.","language":"en","twitter":{"cardType":"summary_large_image","site":"@bayungangeles"},"openGraph":{"title":"Báyung Ángeles – Join the movement!","description":"Báyung Ángeles is a community advocating social change through responsible, effective, and transparent public governance.","type":"website","url":"https://www.bayungangeles.org","images":[{"url":"https://www.bayungangeles.org/preview.png"}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/ba-logo-bg.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e063af0a4b5a389a7b8545721f1fcea0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    }]
